<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :center="true"
      title="结算"
      :visible.sync="payVisible"
      width="666px"
      :before-close="closeEvent"
    >
      <div class="swpay">
        <div class="swpay-content">
          <div class="swpay-left">
            <div class="consumption">
              <span class="yi-left">消费总额:</span>
              <span class="yi-right">{{ "￥" + totalAmount }}</span>
            </div>
            <div class="discount">
              <span class="yi-left">折后支付:</span>
              <span class="yi-right" style="color: #e82742">{{
                "￥" + totalAmount
              }}</span>
            </div>
            <div class="discount">
              <span class="yi-left">
              <el-radio v-model="radio" :label="1">开单</el-radio>
              <el-radio v-model="radio" :label="2" v-if="checkPermission('bdqx')">补单</el-radio>
              </span>
              <span class="yi-right" style="color: #e82742">
                 <el-date-picker
                   v-if="radio==2"
                   size="small"
                   value-format="yyyy-MM-dd HH:mm:ss"
                   v-model="kdDate"
                   type="datetime"
                   placeholder="选择日期时间">
                 </el-date-picker>
              </span>
            </div>
            <div class="swpay-choice">
              <span style="width: 130px;padding-left: 40px;line-height: 70px">支付方式:</span>
              <div class="mode">
                <div class="mode1">
                  <div
                    style="cursor: pointer"
                    class="icon"
                    v-for="(item, index) of iconList"
                    :key="index"
                    @click="changeList(item.type, item.iconDec)"
                  >
                    <img class="imgfont" :class="{ checked: item.type == iconIndex }" :src="item.iconContent">
                    <p class="iconDec">{{ item.iconDec }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="payment-footer">
          <el-button type="primary" class="settlement" :loading="isDisabled" @click="saveMemberCard()">
            {{isDisabled==true?'结算中':'结算'}}
          </el-button>
        </div>
      </div>
    </el-dialog>


    <el-dialog
      :close-on-click-modal="false"
      center
      :title="zfTitle"
      :visible.sync="ptVisible"
      width="400px"
      :before-close="closeCode"
    >
      <div style="margin: 0 auto; text-align: center; width: 100%; height: 100%;margin-top: -20px">
        <el-input v-model="dynamicId" size="mini" ref="dynamic" style="width: 260px;display: block;margin: auto"
                  placeholder="请输入内容"></el-input>
        <img :src="qrCode" style="width: 150px;height: 150px;">
      </div>
    </el-dialog>

    <el-dialog title="提示" :visible.sync="delVisible" width="300px" center>
      <div class="del-dialog-cnt">是否退出结算页面？</div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="delVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="fanhui"
        >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import {getToken} from "../../../utils/auth";
  import {checkPermission} from "../../../utils/main";
  export default {
    name: "cardPay",
    data() {
      return {
        timer: null,
        dynamicId: "",
        totalAmount: 0,
        iconIndex: 2,
        iconList: [
          {
            iconContent: require("../../../assets/images/xj.png"),
            iconDec: "现金",
            type: "2",
            balance: "",
          },
          {
            iconContent: require("../../../assets/images/wx.png"),
            iconDec: "微信",
            type: "3",
            balance: "",
          },
          {
            iconContent: require("../../../assets/images/zfb.png"),
            iconDec: "支付宝",
            type: "4",
            balance: "",
          },
        ],

        isDisabled: false,

        zfTitle: '',
        paySn: "",
        payNo: "",
        qrCode: "",
        ptVisible: false,
        alPay: '',
        titleContent: "",
        titleVisible: false,

        delVisible: false,
        kdDate: '',
        radio:1,
      };
    },
    props: {
      payVisible: Boolean,
      total: Number,
      data: Object
    },
    watch: {
      dynamicId(val) {
        if (val != null && val.length == 18) {
          this.payMethod();
        }
      },
    },
    created() {
      this.totalAmount = this.total;
      this.getDept()
    },
    destroyed() {
      // 在页面销毁后，清除计时器
      this.beforeDestroy()
    },
    methods: {
      checkPermission,
      async getDept(){
        let res = await this.$get("/sw/getSwDept", {token:getToken()});
        if (res.dept.isBtn==0){
          this.iconList.push(
            {
              iconContent: require("../../../assets/images/sjia.png"),
              iconDec: "商家",
              type: "9",
              balance: "",
            },
          )
        }
      },
      closeTitel() {
        this.titleVisible = false;
      },

      closeCode() {
        this.ptVisible = false;
        this.beforeDestroy()
      },

      closeEvent() {
        this.delVisible = true;
      },

      closePay() {
        this.$emit("closePay");
      },
      fanhui() {
        this.$emit("fanhui");
      },
      async changeList(index) {
        this.iconIndex = index;
      },


      async saveMemberCard() {
        this.isDisabled = true
        this.data.payType = this.iconIndex
        this.data.kdType=this.radio
        this.data.kdDate=this.kdDate
        let res = await this.$post("/sw/saveMemberCard", this.data);
        if (res.code == 200) {
          this.isDisabled = false
          this.orderId = res.orderId
          if (res.status==0){
            if(res.payType==3||res.payType==4){
              this.toPay(res.payType,res.orderId)
            }
          }else {
            this.$router.push('/cashiers/cashier');
          }
        } else {
          this.isDisabled = false
          this.$message.error(res.message);
        }
      },

      async toPay(status) {
        if (status == 3 || status == 4) {
          if (status == 3) {
            this.zfTitle = '微信支付'
          }
          if (status == 4) {
            this.zfTitle = '支付宝支付'
          }
        }
        let data = {
          token: getToken(),
          payType: status,
          payName: "购买会员卡",
          orderId: this.orderId,
        };
        this.isDisabled = true
        let res = await this.$post("/sw/precreate", data);
        this.isDisabled = false
        if (res.code == 200) {
          this.qrCode = res.qrCode;
          if (status == 3) {
            this.wxNo = res.payNo;
            this.wxSn = res.sn;
          } else if (status == 4) {
            this.alNo = res.payNo;
            this.alSn = res.sn;
          }
          this.beforeDestroy();
          this.dataRefreh();
          this.ptVisible = true;
          this.$nextTick(() => {
            this.$refs.dynamic.focus() // 设置焦点
          })
        } else {
          this.$message.error(res.message)
        }
      },

      // 定时刷新数据函数
      dataRefreh() {
        // 计时器正在进行中，退出函数
        if (this.timer != null) {
          return;
        }
        // 计时器为空，操作
        this.timer = setInterval(() => {
          this.getPayStatus(); //加载数据函数
        }, 1500);
      },

      // 停止定时器
      beforeDestroy() {
        clearInterval(this.timer); //清除计时器
        this.timer = null; //设置为null
      },

      async getPayStatus() {
        let data = {
          orderId: this.orderId
        };
        let res = await this.$get("/sw/getPayStatus", data);
        if (res.code == 200) {
          // this.titleVisible = true;
          // this.titleContent = res.message;
          // this.$emit("closePay");
          this.$router.push('/cashiers/cashier');
          this.beforeDestroy();
        } else {
          this.$message.error("正在支付中")
        }
      },

      async payMethod() {
        if (!this.dynamicId) {
          this.$message.error("请扫付款码");
          return;
        }
        let data = {
          token: getToken(),
          dynamicId: this.dynamicId,
          payType: this.iconIndex,
          payName: "购买会员卡",
          orderId: this.orderId,
        };
        let res = await this.$post("/sw/payMethod", data);
        if (res.code == 200) {
          // this.titleVisible = true;
          // this.titleContent = "正在支付";
          this.beforeDestroy();
          this.dataRefreh();
        } else {
          this.$message.error(res.message);
          // this.titleContent = res.message;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .swpay {
    width: 100%;
    height: 50vh;
    margin-top: -12px;
  }

  .swpay-content {
    width: 100%;
    height: 83%;
    border-top: #ececec solid 1px;
    display: flex;

    .swpay-left {
      width: 100%;
      height: 100%;
    }

    .yi-left {
      width: 60%;
      display: inline-block;
      font-size: 14px;
    }

    .yi-right {
      display: inline-block;
      width: 35%;
      text-align: right;
      margin-right: 2%;
    }
  }

  .swpay-choice {
    display: flex;
    flex-direction: row;
    border-bottom: #ececec solid 1px;
    height: auto;

    .mode {
      width: 100%;
      display: flex;
    }
  }

  .consumption,
  .discount {
    height: 40px;
    line-height: 40px;
    padding-left: 40px;
    border-bottom: #ececec solid 1px;
    color: #666666;
  }

  //支付方式
  .mode1 {
    // height: 50px;
    .icon {
      width: 55px;
      height: 70px;
      line-height: 40px;
      float: left;
      // margin-left: 10px;
      margin-top: 5px;
      text-align: center;

      .imgfont {
        width: 35px;
        height: 35px;
      }
    }

    .imgfont.checked {
      border-radius: 50%;
      border: #eb2742 solid 1px;
      background-color: #eb2742;
      color: #fff;
    }
  }

  .payment-footer {
    width: 100%;
    height: 15%;

    .settlement {
      width: 100%;
      height: 50px;
      border: 0;
      background-color: #eb2742;
      cursor: pointer;
      color: #fff;
    }
  }

  .iconDec {
    width: 55px;
    margin-top: -20px;
    font-size: 13px;
  }
</style>
