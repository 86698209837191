<template>
  <div class="web-cards">
    <div class="card-top">
      <div class="ct-left">
        <div><span class="ct-title">会员卡</span></div>
        <div class="gd">
          <div class="card-items" v-if="cards.length>0">
            <div class="card-item" v-for="item in cards" @click="addCard(item)">
              <img :src="item.imgUrl" class="card-img">
              <span class="cti-name">{{item.cardName}}
                <span v-if="item.cardType==0" style="color:#A2A2A2;font-size: 10px"> (储值卡)</span>
                <span v-if="item.cardType==1" style="color:#A2A2A2;font-size: 10px">(年卡)</span>
                <span v-if="item.cardType==2" style="color:#A2A2A2;font-size: 10px">(消费卡)</span>
              </span>
              <span class="cti-price">¥ {{item.openAmount}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="ct-right">
        <div class="cjmb">
          <el-button size="small" type="primary" @click="toMembers">查询客户</el-button>
          <el-button size="small" type="primary" @click="toAddMember">创建客户</el-button>
          <span style="margin-left: 28px;cursor: pointer" v-if="member.memberName" @click="toUpdateName">顾客: {{member.memberName}}</span>
        </div>
        <div class="buy-cards">
          <el-table
            ref="singleTable"
            height="100%"
            :data="datas"
            highlight-current-row
            style="width: 100%">
            <el-table-column width="50" align="center">
              <template slot-scope="scope">
                <li class="el-icon-delete-solid" style="color:red;cursor: pointer;" @click="delCard"></li>
              </template>
            </el-table-column>
            <el-table-column property="cardName" label="名称" width="100" align="center">
            </el-table-column>
            <el-table-column property="openAmount" label="价格" width="90" align="center">
              <template slot-scope="scope">
                <el-input v-model="scope.row.openAmount" :disabled="scope.row.changeAmount==1"
                          size="mini" v-on:input="calculation(scope.row.openAmount)"></el-input>
              </template>
            </el-table-column>
            <el-table-column property="giveMoney" label="赠送金" width="90" align="center">
              <template slot-scope="scope">
                <el-input v-model="scope.row.giveMoney" :disabled="scope.row.changeAmount==1" size="mini"></el-input>
              </template>
            </el-table-column>
            <el-table-column property="empName" label="销售" align="center">
              <template slot-scope="scope">
                <div class="buy-xiaoshou" v-for="item in scope.row.emps">
                  <span style="width: 60px;line-height: 28px;font-size: 13px">{{item.empName}}</span>
                  <el-input v-model="item.amount" size="mini" style="width: 90px;"></el-input>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="card-emp" v-if="exist">
          <div class="card-gw" v-if="fEmps.length>0">
            <span class="gw-title">{{fName}}</span>
            <div class="emp-h">
              <div class="gw-emp" v-for="item in fEmps" @click="setEmp(item,1)">
                <span class="name" :class="{hui: item.check}">{{item.nickName}}</span>
              </div>
            </div>
          </div>
          <div class="card-gw" v-if="sEmps.length>0">
            <span class="gw-title">{{sName}}</span>
            <div class="emp-h">
              <div class="gw-emp" v-for="item in sEmps" @click="setEmp(item,2)">
                <span class="name" :class="{hui: item.check}">{{item.nickName}}</span>
              </div>
            </div>
          </div>
          <div class="card-gw" v-if="tEmps.length>0">
            <span class="gw-title">{{tName}}</span>
            <div class="emp-h">
              <div class="gw-emp" v-for="item in tEmps" @click="setEmp(item,3)">
                <span class="name" :class="{hui: item.check}">{{item.nickName}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-bottom">
      <div class="cjs-btn">
        <span>总计：</span>
        <span class="total">￥{{ total }}</span>
        <button class="payment" @click="saveCardOrder">结 算</button>
      </div>
    </div>

    <TabMemberList
      v-if="mbVisible"
      :mbVisible="mbVisible"
      @setMember="setMember"
      @closeML="closeML"
    />

    <TabSaveMember
      v-if="smVisible"
      :smVisible="smVisible"
      @setMember="setMember"
      @closeSM="closeSM"
    />

    <TabPayment
      v-if="payVisible"
      :payVisible="payVisible"
      :total="total"
      :member="member"
      :orderId="orderId"
      :data="data"
      @closePay="closePay"
      @fanhui="fanhui"
    />
    <el-dialog
      :close-on-click-modal="false"
      center
      title="提示"
      :visible.sync="titleVisible"
      width="15%"
      :before-close="closeTitel"
    >
      <div style="margin: 0 auto; text-align: center; width: 100%; height: 100%">
        <span style="font-weight: bold; font-size: 18px">结算成功</span>
      </div>
      <br/>
    </el-dialog>

    <el-dialog
      :close-on-click-modal="false"
      title="编辑姓名"
      :visible.sync="editVisible"
      width="300px"
    >
      <div style="margin-top: -20px">
        <el-input v-model="name" size="mini" style="width: 260px;display: block;margin: auto"
                  placeholder="请输入姓名"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="qrMethod">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import {getToken} from "../../utils/auth";
  import TabMemberList from "../cards/member/memberList";
  import TabSaveMember from "../common/member/saveMember";
  import TabPayment from "../cards/payment/payment";

  export default {
    name: "card",
    components: {
      TabMemberList,
      TabSaveMember,
      TabPayment,
    },
    data() {
      return {
        orderId: '',
        orderInfoId: '',
        cards: [],
        datas: [],

        exist: false,
        fEmps: [],
        sEmps: [],
        tEmps: [],

        member: {},
        memberId: '',
        orderSex: '',

        members: [],

        total: 0,

        donationAmount: 0,
        saveVisible: false,
        mbVisible: false,
        smVisible: false,
        payVisible: false,
        titleVisible: false,
        loading: false,

        name: '',
        editVisible: false,

        data: {},
      };
    },

    async created() {
      this.getCards();
      this.getEmpList();
    },

    methods: {
      closeTitel() {
        this.titleVisible = false;
      },
      //获取会员卡列表
      async getCards() {
        let res = await this.$get("/sw/getSwCardList", {token: getToken()});
        this.cards = res.cards;
      },
      //添加会员卡
      addCard(item) {
        this.datas = []
        this.datas.push({
          cardId: item.id,
          cardName: item.cardName,
          openAmount: item.openAmount,
          oglPrice: item.openAmount,
          giveMoney: item.giveMoney,
          cardAmount: item.cardAmount,
          changeAmount: item.changeAmount,
          cardType: item.cardType,
          setPwd: item.setPwd,
          ckCmn: item.ckCmn,
          emps: [],
          token: getToken(),
        })
        this.total = item.openAmount
        this.getEmpList()
      },
      //获取员工列表
      async getEmpList() {
        let res = await this.$get("/sw/getSwEmpList", {token: getToken()});
        this.fEmps = res.fEmps;
        this.sEmps = res.sEmps;
        this.tEmps = res.tEmps;
        this.fName = res.fName;
        this.sName = res.sName;
        this.tName = res.tName;
        this.exist = res.exist;
      },

      //移除已选择的会员卡
      delCard() {
        this.datas = []
        this.getEmpList()
      },

      //选择会员信息
      setMember(item) {
        this.member = item;
        this.memberId = item.id
        this.closeML()
        this.closeSM();
      },

      //编辑会员姓名
      toUpdateName() {
        this.editVisible = true
        this.name = this.member.memberName
      },

      async qrMethod() {
        let data = {
          id: this.member.id,
          memberName: this.name
        }
        let res = await this.$post("/sw/updateMemberName", data);
        if (res.code == 200) {
          this.editVisible = false
          this.member.memberName = this.name
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
      },

      //计算总金额
      calculation(total) {
        this.total = total
        this.setEmpJg()
      },

      //设置员工
      async setEmp(item, type) {
        // let amount = 0
        // if (this.datas[0].emps.length == 0) {
        //   amount = this.total
        // }
        if (this.datas.length > 0) {
          if (!item.check) {
            this.datas[0].emps.push({
              empId: item.id,
              empName: item.nickName,
              amount: 0,
              station: type,
            })
          } else {
            let index = this.datas[0].emps.findIndex(it => it.empId == item.id);
            this.datas[0].emps.splice(index, 1)
          }
          item.check = !item.check
          this.setEmpJg()
        }
      },
      setEmpJg(){
        var total=parseFloat(this.total)
        if (total>0){
          if (this.datas.length>0&&this.datas[0].emps.length>0){
            var len = this.datas[0].emps.length
            var dan= parseFloat(total)/ parseFloat(len)
            this.datas[0].emps.forEach(item=>{
              item.amount=dan
            })
          }
        }
      },

      async saveCardOrder() {
        if (!this.memberId) {
          this.$message.error("请选择会员")
          return;
        }
        if (this.datas.length <= 0) {
          return;
        }
        let data = JSON.parse(JSON.stringify(this.datas[0]))
        if (data.emps.length > 0) {
          let yj = 0
          data.emps.forEach(item => {
            yj = parseInt(yj) + parseInt(item.amount)
          })
          if (yj != data.openAmount) {
            this.$message.error("员工业绩总和不能大于办卡价格!")
            return;
          }
          data.emps = JSON.stringify(data.emps)
        }

        data.total = this.total
        data.memberId = this.memberId
        data.orderSex = this.orderSex
        this.data = data
        this.payVisible = true;
      },


      closePay() {
        this.payVisible = false;
        this.titleVisible = true
      },

      fanhui() {
        this.payVisible = false;
      },

      toAddMember() {
        this.smVisible = true;
      },

      closeSM() {
        this.smVisible = false;
      },

      toMembers() {
        this.mbVisible = true;
      },

      closeML() {
        this.mbVisible = false;
      },


    },

  };
</script>


<style lang="scss" scoped>
  .web-cards {
    width: 100%;
    height: 100%;
    background-color: #F2F2F2;
    margin-right: 5px;
  }

  .card-top {
    width: 100%;
    height: 93%;
    background-color: #fff;
    display: flex;
    flex-direction: row;
  }

  .ct-left {
    width: 60%;
    border-right: #F2F2F2 solid 5px;
    height: 100%;
  }

  .ct-title {
    display: block;
    height: 50px;
    line-height: 50px;
    border-bottom: #E6E6E6 solid 1px;
    text-align: center;
    font-weight: bold;
  }

  .gd {
    height: 90%;
    overflow: hidden; // 超出部分隐藏
    overflow-y: scroll; // 设置y轴方向的滚动条
  }

  .card-items {
    width: 95%;
    padding: 0px 2%;
    display: flex;
    flex-flow: row wrap;
  }

  .card-item {
    /*flex: 1;*/
    box-sizing: border-box;
    width: 168px;
    height: 138px;
    padding: 8px;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    /*width: calc((100% - 60px) / 4); // 我这里一行显示4个 所以是/4  一行显示几个就除以几*/
    /*min-width: calc((100% - 60px) / 4);*/
    /*max-width: calc((100% - 60px) / 4);*/
    /*&:nth-child(4n + 4) {*/
    /*  margin-right: 0;*/
    /*}*/
  }

  .card-item:hover {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -webkit-transition: all 0.1s ease-out;
    transition: all 0.1s ease-out;
  }

  .card-img {
    width: 100%;
    height: 98px;
    border-radius: 3px;
  }

  .cti-name {
    margin-top: 3px;
    line-height: 20px;
    margin-left: 5px;
    font-size: 13px;
  }

  .cti-price {
    line-height: 20px;
    margin-left: 5px;
    color: red;
    font-size: 15px;
  }

  .ct-right {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .cjmb {
    display: block;
    height: 50px;
    line-height: 50px;
    border-bottom: #E6E6E6 solid 1px;
    flex-direction: row;
    padding-left: 26px;
    border-right: #F2F2F2 solid 5px;
    font-size: 13px;
  }

  .buy-cards {
    height: 65%;
    border-right: #F2F2F2 solid 5px;
    font-size: 11px;
  }

  .buy-xiaoshou {
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
  }

  .card-emp {
    height: 30%;
    display: flex;
    flex-direction: row;
    border-top: #F2F2F2 solid 5px;
  }

  .emp-h {
    overflow: hidden;
    overflow-y: scroll;
    height: calc(100% - 35px);
  }

  .card-gw {
    width: 100%;
    border-right: #F2F2F2 solid 5px;
  }

  .gw-title {
    width: 100%;
    text-align: center;
    display: block;
    height: 26px;
    line-height: 26px;
    border-bottom: #F2F2F2 solid 1px;
    margin-bottom: 5px;
  }

  .gw-emp {
    display: flex;
    height: 26px;
    line-height: 26px;
    flex-direction: column;
    margin-bottom: 5px;
  }

  .gw-emp .name {
    width: 100%;
    text-align: left;
    padding-left: 30px;
    cursor: pointer;
  }

  .gw-emp .hui {
    width: 100%;
    text-align: left;
    cursor: pointer;
    padding-left: 30px;
    background-color: #DDDDDD;
  }

  .card-bottom {
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
    height: 6%;
    background-color: #FFFFFF;
  }

  .cjs-btn {
    width: 328px;
    height: 100%;
    float: right;
    display: flex;
    align-items: center;
  }

  .cjs-btn span {
    line-height: 51px;
    width: 50px;
  }

  .cjs-btn .total {
    width: 50%;
    color: red;
  }

  .payment {
    float: right;
    width: 150px;
    height: 51px;
    border: 0;
    background-color: #e82742;
    color: #fff;
    cursor: pointer;
  }

</style>
